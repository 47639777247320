
.project-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    padding-top: 80px; /* Adjust this value so that the content of ProjectSection starts just below the Navbar */
    position: relative;
    z-index: 2; /* Make sure this is above the fixed video background */
}
  
  .project-container > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
  }
  
  .project-container > p {
    color: #fff;
    font-size: 24px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .project-btns {
    margin-top: -60px;
  }
  
  .project-btns .btn {
    margin: 6px;
  }
  
  .fa-external-link-alt {
    margin-left: 8px;
  }
  
  @media screen and (max-width: 960px) {
    .project-container > h1 {
      font-size: 100px;
      margin-top: -150px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .project-container > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
  
    .project-container > p {
      font-size: 30px;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
  }
  